@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbarHide::-webkit-scrollbar {
	display: none;
}

/* For IE, Edge and Firefox */
.scrollbarHide {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* To remove bg-blue color when we click on a component (mainly in mobile view) */
.tap-transparent {
	-webkit-tap-highlight-color: transparent;
	tap-highlight-color: transparent;
}

.mediumPlaceholder::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: 400 !important;
	color: #8c8594;
}

.mediumPlaceholder:focus::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: 500 !important;
	color: #8c8594;
}

.mediumPlaceholder:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	font-weight: 400 !important;
	color: #8c8594;
}
.mediumPlaceholder:focus:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	font-weight: 500 !important;
	color: #8c8594;
}

.mediumPlaceholder::-ms-input-placeholder {
	/* Microsoft Edge */
	font-weight: 400 !important;
	color: #8c8594;
}

.active {
	background-color: #eaf8f4;
}
.mediumPlaceholder::-ms-input-placeholder {
	/* Microsoft Edge */
	font-weight: 500 !important;
	color: #8c8594;
}

.otp-input-container-styles {
	display: grid;
	grid-template-columns: repeat(4, 40px);
	gap: 24px;
}
.otp-input-styles {
	border: 1px solid #1f8268;
	background: 0 0;
	border-radius: 4px;
	width: 48px !important;
	height: 48px !important;
	text-align: center;
	font-size: 14px;
	color: #31445a;
	font-weight: 400;
	line-height: 20px;
	outline: 0;
	color-scheme: normal;
}
.otp-input-styles-error {
	border: 1px solid #cc0000;
	background: 0 0;
	border-radius: 4px;
	width: 48px !important;
	height: 48px !important;
	text-align: center;
	font-size: 14px;
	color: #31445a;
	font-weight: 400;
	line-height: 20px;
	outline: 0;
}

.scrollbar-thin::-webkit-scrollbar {
	display: none;
}

@media only screen and (max-width: 600px) {
	.crisp-client .cc-1brb6[data-full-view="true"] .cc-1yy0g a.cc-1m2mf {
		bottom: 150px !important;
		right: 20px !important;
	}
}

.apnaLearnCustomGradient {
	background: linear-gradient(86deg, #ebf3fe 3.31%, #c8dfff 96.69%), #ebf3fe;
}
.apnaLearnFormCustomGradient {
	background: radial-gradient(175.7% 141.42% at 0% 0%, #f1eafa 52.22%, #dec4ff 100%),
		linear-gradient(86deg, #ebf3fe 3.31%, #d1e4ff 96.69%),
		linear-gradient(0deg, #272f6a 0%, #272f6a 100%),
		linear-gradient(86deg, #ebf3fe 3.31%, #d1e4ff 96.69%), #ebf3fe;
}
.apnaAdvantageTextGradient {
	background: linear-gradient(92deg, #EFB94C 8.52%, #F9DF7B 32.82%, #FFF3A6 50.21%, #F9DF7B 67.14%, #EFB94C 91.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; /* For non-WebKit browsers supporting this property */
  color: transparent;
}

@keyframes shine {
	0% {
	  transform: translateX(-200%) ;
	}
	100% {
	  transform: translateX(200%) ;
	}
  }
  
  .shiny-effect {
	position: relative;
	overflow: hidden;
  }
  
  .shiny-effect::after {
	content: "";
	position: absolute;
	top: -30%;
	left: -150%;
	width: 300%; /* Reduced width */
	height: 150%;
	background: linear-gradient(
	  70deg,
	  rgba(255, 255, 255, 0) 44%,
	  rgba(255, 255, 255, 0.20) 47%,
	  rgba(255, 255, 255, 0.20) 50%,
	  rgba(255, 255, 255, 0) 53%
	);
	animation: shine 4s infinite linear;
  }
  .shiny-effect::before {
	content: "";
	position: absolute;
	top: -30%;
	left: -120%;
	width: 300%; /* Reduced width */
	height: 150%;
	background: linear-gradient(
	  70deg,
	  rgba(255, 255, 255, 0) 44%,
	  rgba(255, 255, 255, 0.20) 47%,
	  rgba(255, 255, 255, 0.20) 50%,
	  rgba(255, 255, 255, 0) 53%
	);
	animation: shine 4s infinite linear;
  }
  
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  .animate-fade-in {
	animation: fadeIn 0.5s ease-in forwards;
  }

  @font-face {
    font-family: 'Lato-Regular';
    src: url('/Lato-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@layer utilities {
	.shimmer-effect::before {
	  content: "";
	  position: absolute;
	  top: 0;
	  left: -150px;              /* Start position off-screen */
	  width: 32px;              /* Narrower beam */
	  height: 100%;
	  background: white;
	  opacity: 0.5;
	  filter: blur(16px);
	  transform: skewX(-20deg);
	  animation: shimmer-left-to-right 2s infinite;
	  border-radius: inherit;
	}
  
	@keyframes shimmer-left-to-right {
	  0% {
		left: -150px;
	  }
	  100% {
		left: 100%;
	  }
	}
  }
  